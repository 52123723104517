import React, { Component } from 'react'
import Render from './render.js'
import Render_M from './render_m.js'


import * as stage2_audio from '../assets/audio/stage2.m4a'
import * as bip from '../assets/audio/bip.wav'
import * as sacred from '../assets/audio/sacred.wav'
import * as end from '../assets/audio/end.wav'

import * as pfl from '../assets/pfl.png'
import * as ear from '../assets/ear.png'
import * as ear2 from '../assets/ear2.png'


class Main extends Component {
  constructor(props) {
    super(props)
    this.state = {
      render:{},
      volume: true
    }
  }

  componentDidMount() {
    console.log(window.innerWidth)
    if(window.innerWidth < 400) {
      let render = new Render_M()
      this.setState({render}, () => {
        this.state.render.init()
      })
    } else {
      let render = new Render()
      this.setState({render}, () => {
        this.state.render.init()
      })
    }

    this.resize()


  }

  resize() {

    let stage1 = document.querySelector('.stage1')
    let stage2 = document.querySelector('.stage2')
    let stage3 = document.querySelector('.stage3')
    let intro = document.querySelector('.intro')

    stage1.style.top = stage2.style.top = stage3.style.top = window.innerHeight/2 + 'px'
    intro.style.height = window.innerHeight + 'px'
  }

  initRender(sound) {
    document.body.style.cursor = 'none';
    document.querySelector('.volume').style.display = 'block'
    if(sound) {

    } else {
      this.setState({volume:false})
      document.querySelectorAll('audio').forEach(i => {
        i.muted = true;
      })
    }
    this.state.render.start()

    document.querySelector('.intro').style.display = 'none'
    document.querySelector('.canvas').style.display = 'block'
    document.querySelectorAll('audio').forEach(i => {
      i.play()
      i.pause()
    })
  }

  changeV() {
    if(this.state.volume) {
      this.setState({volume:false})
      document.querySelectorAll('audio').forEach(i => {
        i.muted = true;
      })
    }else {
      this.setState({volume:true})
      document.querySelectorAll('audio').forEach(i => {
        i.muted = false;
      })
    }
  }

  render() {
    return(
      <div>
        <style>
          {`
            <link rel="stylesheet" href="https://use.typekit.net/edg6agc.css">
            @import url("https://use.typekit.net/edg6agc.css");

            body {
              cursor: none;
            }

            .canvas {
              display: none;
            }
            .stage1 {
              font-family: campaign,sans-serif;
              font-weight: 400;
              font-style: normal;
              font-size: 20vw;

              position: fixed;
              top: 50vh;
              left: 50vw;
              transform: translate(-50%, -50%);
              display: none;
              z-index: 9999;
              color: #fff;
              mix-blend-mode: difference;
              pointer-events: none;
            }

            .stage2 {
              font-family: campaign,sans-serif;
              font-weight: 400;
              font-style: normal;
              font-size: 20vw;

              position: fixed;
              top: 50vh;
              left: 50vw;
              transform: translate(-50%, -50%);
              display: none;
              z-index: 9999;
              color: #fff;
              mix-blend-mode: difference;
              pointer-events: none;
            }

            .stage3 {
              font-family: campaign,sans-serif;
              font-weight: 400;
              font-style: normal;
              font-size: 20vw;

              position: fixed;
              top: 50vh;
              left: 50vw;
              transform: translate(-50%, -50%);
              display: none;
              z-index: 9999;
              color: #fff;
              mix-blend-mode: difference;
              pointer-events: none;
            }

            .overlay {
              width: 100vw;
              height: 100vh;
              z-index: 1;
              position: fixed;
              left: 0;
              top: 0;
              mix-blend-mode: difference;
              background-color: #fff;
              pointer-events: none;
            }
            .bg {
              width: 100vw;
              height: 100vh;
              z-index: -1;
              position: fixed;
              left: 0;
              top: 0;
              background-color: #fff;
            }

            .ending {
              display: none;
              position: fixed;
              font-family: campaign,sans-serif;
              font-weight: 400;
              font-style: normal;
              font-size: 5vw;

              left: 20px;
              top: 20px;
              width: 60vw;
            }

            .portfolio {
              display: none;
              width: 30vw;
              height: 30vw;
              position: fixed;
              right: 20px;
              bottom: 20px;
              align-items: center;
              justify-content: center;

              position: fixed;
              font-family: campaign,sans-serif;
              font-weight: 400;
              font-style: normal;
              font-size: 5vw;

              transform: rotate(10deg);
            }

            .intro {
              font-family: campaign,sans-serif;
              font-weight: 400;
              font-style: normal;
              font-size: 20px;

              width: 100vw;
              height: 100vh;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .intro * {
              text-align: center;
            }

            span {
              padding: 20px;
              border: 2px solid #000;
              margin: 30px 20px;
              box-sizing: border-box;
              display: inline-block;
              border-radius: 20px;
              cursor: pointer;
            }

            .contact {
              font-family: campaign,sans-serif;
              font-weight: 400;
              font-style: normal;
              font-size: 30px;
              z-index: 999;
              color: #fff;

              display: none;
              position: fixed;
              left: 20px;
              bottom: 20px;
            }

            .contact::after {
              content:'bluemanzhang@gmail.com';
            }

            .volume {
              position: fixed;
              width: 100px;
              right: 0;
              top: 0;
              opacity: .5;
              display: none;
            }



            .volume img{
              width: 100%;
            }

            .volume:hover {
              opacity: 1;
            }

            @media only screen and (max-width: 900px) {
              .volume:hover {
                opacity: .5 !important;
              }
              .contact::after {
                content:'@email';
              }
              .ending {
                font-size: 6vw;
                width: 70vw;
              }
              .portfolio {
                top: 50vh;
                left: 50vw;
                transform: translate( -50%, -50% ) scale(.5) rotate(15deg);
              }

            }

            .italic {
              font-family: campaign, sans-serif;
              font-weight: 400;
              font-style: italic;
              display: inline;
            }


          `}
        </style>
        <div className='overlay'></div>
        <audio className='stage1_audio' src={stage2_audio}></audio>
        <div className='stage1'> Hello. </div>
        <audio className='stage2_audio' src={stage2_audio}></audio>
        <div className='stage2'> I am Yifu. </div>
        <audio className='stage3_audio' src={stage2_audio}></audio>
        <div className='stage3'> I can design. </div>

        <audio className='bip' src={bip}></audio>
        <audio className='end' loop src={end}></audio>
        <audio className='sacred' src={sacred}></audio>
        <audio className='stage4_audio' loop src={stage2_audio}></audio>
        <div className='canvas'></div>
        <div className='bg'></div>


        <div className='ending'>I build this in case I can not say <p className='italic'>HELLO</p> to you in person!</div>
        <a href='mailto:bluemanzhang@gmail.com?subject=Hello%20There!'><div className='contact'></div></a>
        <div className='portfolio'><a href='https://yifuzhang.xyz'><img src={pfl} /></a></div>

        <div className={this.state.volume?'volume':'volume active'} onClick={() => this.changeV()}><img src={this.state.volume?ear:ear2} /></div>
        <div className='intro'>
          <div>
            <div className='title'>This site has sounds, start with audio?</div>
            <div>
              <span onClick={() => this.initRender(true)}>Cool</span>
              <span onClick={() => this.initRender(false)}>Meh</span>
            </div>
          </div>
        </div>

      </div>
    )
  }
 }

export default Main
