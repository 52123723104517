import * as PIXI from 'pixi.js'
import * as tex from '../assets/tex_arm.png'
import * as tex_hand from '../assets/tex_hand.png'
import * as tex_hand2 from '../assets/tex_hand2.png'
import * as tex_hand3 from '../assets/tex_hand3.png'

import * as tex_me from '../assets/tex_me.png'
import * as tex_thumb from '../assets/tex_thumb.png'
import * as tex_door from '../assets/tex_door.png'
import * as tex_me2 from '../assets/tex_me2.png'
import * as tex_me3 from '../assets/tex_me3.png'
import * as tex_pc from '../assets/tex_pc.png'
import * as tex_pc2 from '../assets/tex_pc2.png'
import * as tex_pc3 from '../assets/tex_pc3.png'

import * as tex_mask from '../assets/tex_mask2.png'



var app,
    stage,
    step = 1,
    w,
    h,
    coord = [0,0],
    origin = {x:40,y:40},
    armPts = [],
    handPt, shoulderPt,
    arm,hand,me,thumb,portrait,pc,pc2,pc3,mask,
    state = 'idel'

var stage2_in = false,
    stage2_drop = false,
    stage2_step = 0

var stage3_step = 0

class render {

  init() {
    app = new PIXI.Application({ transparent: true, antialias: true, backgroundColor: 0x000000 });
    document.querySelector('.canvas').appendChild(app.view);


    let texture_me = PIXI.Texture.from(tex_me);
    let texture_me2 = PIXI.Texture.from(tex_me2);
    let texture_me3 = PIXI.Texture.from(tex_me3);
    let texture_hand = PIXI.Texture.from(tex_hand);
    let texture_hand2 = PIXI.Texture.from(tex_hand2);
    let texture_hand3 = PIXI.Texture.from(tex_hand3);
    let texture_pc = PIXI.Texture.from(tex_pc);
    let texture_pc2 = PIXI.Texture.from(tex_pc2);
    let texture_pc3 = PIXI.Texture.from(tex_pc3);
    let texture_thumb = PIXI.Texture.from(tex_thumb);
    let texture_door = PIXI.Texture.from(tex_door);
    let texture_mask = PIXI.Texture.from(tex_mask);



    app.view.style.position = 'fixed';
    app.view.style.zIndex = '-999';
    app.sortableChildren = true;

    app.view.width = window.innerWidth;
    app.view.height = window.innerHeight;
    app.view.style.top = 0;

    window.addEventListener('resize', resize);

    function resize () {
      app.view.width = window.innerWidth;
      app.view.height = window.innerHeight;
      w = app.view.width
      h = app.view.height
    }

    stage = new PIXI.﻿Container();﻿﻿﻿﻿﻿﻿
    stage.sortableChildren = true;﻿
    app.stage.addChild(stage);﻿﻿﻿﻿

    resize()

    stage = new PIXI.﻿Container();﻿﻿﻿﻿﻿﻿
    stage.sortableChildren = true;﻿
    app.stage.addChild(stage);﻿﻿﻿﻿

    document.onmousemove = (e) => {
      coord[0] = e.pageX
      coord[1] = e.pageY
    }


    //draw arm
    for (let i = 0; i < 20; i++) {
      let pt = new PIXI.Point(-i * window.innerWidth/40, -i*window.innerHeight/40)
      armPts.push(pt);
      pt.forceX = 0
      pt.forceY = 0
      pt.speedX = 0
      pt.speedY = 0
    };

    handPt = new PIXI.Point( 40, 40)
    shoulderPt = new PIXI.Point(-1000, -1000)

    arm = new PIXI.SimpleRope(PIXI.Texture.from(tex), [shoulderPt, ...armPts, handPt]);

    hand = new PIXI.Sprite(texture_hand)
    hand.anchor.set(0,.5)
    hand.rotation = 0
    hand.zIndex = 1
    hand.interactive = true

    //hand draggable
    hand.on('touchstart', onTouchStart)

    function onTouchStart() {

    }

    hand
        .on('touchstart', onDragStart)
        .on('touchend', onDragEnd)
        .on('touchendoutside', onDragEnd)
        .on('touchmove', onDragMove);

    function onDragStart(event) {
        // store a reference to the data
        // the reason for this is because of multitouch
        // we want to track the movement of this particular touch
        this.data = event.data;
        this.dragging = true;
    }

    function onDragEnd() {
        this.dragging = false;
        // set the interaction data to null
        this.data = null;
    }

    function onDragMove() {
        if (this.dragging && state === 'playing') {
            const newPosition = this.data.getLocalPosition(this.parent);
            this.x = newPosition.x;
            this.y = newPosition.y;
        }
    }

    //drag finished

    thumb = new PIXI.Sprite(texture_thumb)
    thumb.anchor.set(0,.5)
    thumb.rotation = 0
    thumb.zIndex = 3

    me = new PIXI.Sprite(texture_me)
    me.anchor.set(-.06,.55)
    me.x = w - 150
    me.y = h - 200
    me.rotation = 1
    me.zIndex = 2

    stage.addChild(arm)
    stage.addChild(thumb)
    stage.addChild(hand)
    stage.addChild(me)


    app.ticker.add((delta) => {

      let dy = handPt.x - armPts[armPts.length-1].x
      let dx = handPt.y - armPts[armPts.length-1].y
      let theta = Math.atan2(dy, dx)
      hand.rotation = -theta + Math.PI/2
      thumb.rotation = -theta + Math.PI/2


      if(state === 'playing') {
        switch(step) {
          case 1 :
            playStage1()
            break;
          case 2 :
            playStage2()
            break;
          case 3 :
            playStage3()
            break;
          default:
            break;
        }
      }

      if(hand.dragging && Math.abs(hand.x - me.x) < 50 && Math.abs(hand.y - me.y) < 50 && step == 1) {
        if(state === 'playing') {
          state = 'finishing'
        }
        finish1()
      }

      if(hand.dragging && Math.abs(hand.x - w/2) < 100 && Math.abs(hand.y - me.y) < 100 && step == 2 && stage2_step == 3) {
        if(state === 'playing') {
          state = 'finishing'
        }
        finish2()
      }

      if(hand.dragging && Math.abs(hand.x + 100 - me.x) < 100 && Math.abs(hand.y - 100 - me.y) < 100 && step == 3 && stage3_step == 4) {
        if(state === 'playing') {
          state = 'finishing'
        }
        stage3_step = 5
      }

      if( stage3_step == 5 ) {
        finish3()
      }


      if(state === 'finishing') {
        state = 'end'
        switch(step) {
          case 1:
            setTimeout(stage1end,1000)
            break;
          case 2:
            setTimeout(stage2end,1000)
            break;
          case 3:
            setTimeout(stage3end,1000)
            break;
          default:
            break;
        }
      }

    })

    //playing playStage1
    //stage1
    function playStage1() {


      handPt.x = hand.x
      handPt.y = hand.y
      thumb.x = hand.x
      thumb.y = hand.y
      shoulderPt.x = hand.x - window.innerWidth
      shoulderPt.y = hand.y - window.innerHeight/2

      if(!hand.dragging) {
        moveTo(hand,origin)
        moveTo(handPt,origin)
        moveTo(thumb,origin)
      }



      armPts.forEach((pt,index) => {
        pt.index = index

        if(index === 0) {
          updatePt(
            pt,
            armPts[index+1],
            shoulderPt
          )
        } else if (index === armPts.length-1) {
          updatePt(
            pt,
            handPt,
            armPts[index-1]
          )
        } else {
          updatePt(
            pt,
            armPts[index+1],
            armPts[index-1]
          )
        }
      })
    }

    //updatePt
    function updatePt(pt, next, prev) {
      let weight = .015 * pt.index

      if(Math.abs(Math.atan2(prev.x - pt.x, prev.y - pt.y)-Math.atan2(next.x - pt.x, next.y - pt.y)) < 10) {
        pt.x = (next.x + prev.x)/2
        pt.y = (next.y + prev.y)/2

      }


      pt.targetX = (next.x+prev.x)/2
      pt.forceX = pt.targetX - pt.x
      pt.speedX += pt.forceX * weight
      pt.speedX *= .7
      pt.x += pt.speedX

      if( Math.abs(pt.forceX) > 10 ) {
        pt.x = pt.targetX - Math.sign(pt.forceX)*10
        pt.speedX = 0
      }

      if( Math.abs(pt.forceY) > 10 ) {
        pt.y = pt.targetY - Math.sign(pt.forceY)*10
        pt.speedY = 0
      }


      pt.targetY = (next.y+prev.y)/2
      pt.forceY = pt.targetY - pt.y
      pt.speedY += pt.forceY * weight
      pt.speedY *= .7
      pt.y += pt.speedY



    }

    function finish1() {
      moveTo(hand,me)
      moveTo(thumb,me)
      moveTo(handPt,me)


      let shoulderDes = {x:me.x - w, y:me.y - h}
      moveTo(shoulderPt,shoulderDes)


      armPts.forEach((pt,index) => {
        let i = Math.abs(index - armPts.length-1)
        if(Math.abs(me.x - (2 * (i+1)) - pt.x)>1) {
          pt.x += (.01*index) * (me.x - (2 * (i+1)) - pt.x)
        }
        if(Math.abs(me.y - (5 * i) - pt.y)>1) {
          pt.y += (.01*index) * (me.y - (5 * i) - pt.y)
        }
      })
    }

    function finish2() {
      moveTo(hand,{x:w/2, y:me.y})
      moveTo(handPt,{x:w/2, y:me.y})

      let shoulderDes = {x:me.x + w, y:me.y + h}
      moveTo(shoulderPt,shoulderDes)


      armPts.forEach((pt,index) => {
        let i = Math.abs(index - armPts.length-1)
        if(Math.abs(w/2 - pt.x)>1) {
          pt.x += (.01*index) * (w/2 - pt.x)
        }
        if(Math.abs(me.y + (10 * i) - pt.y)>1) {
          pt.y += (.01*index) * (me.y + (10 * i) - pt.y)
        }
      })
    }

    function finish3() {
      moveTo(hand,{x:me.x - 200, y:me.y + 20})
      moveTo(handPt,{x:me.x - 200, y:me.y + 20})

      let shoulderDes = {x:me.x-w/2, y:me.y + h}
      moveTo(shoulderPt,shoulderDes)

      armPts.forEach((pt,index) => {
        let i = Math.abs(index - armPts.length-1)
        if(Math.abs(me.x - 200 - (20 * (i*i)) - pt.x)>1) {
          pt.x += (.01*index) * (me.x - 200 - (20 * (i*i)) - pt.x)
        }
        if(Math.abs(me.y + 20 + (10 * i) - pt.y)>1) {
          pt.y += (.01*index) * (me.y + 20 + (10 * i) - pt.y)
        }
      })
    }

    function moveTo(a,b) {
      if(Math.abs(-a.x + b.x)>1) {
        a.x += .5 * (-a.x + b.x)
      }
      if(Math.abs(-a.y + b.y)>1) {
        a.y += .5 * (-a.y + b.y)
      }
    }

    function stage1end() {
      document.querySelector('.stage1').style.display = 'flex'
      document.querySelector('.stage1_audio').play()

      setTimeout(startStage2,3000)
    }

    function stage2end() {
      document.querySelector('.stage2').style.display = 'flex'
      document.querySelector('.stage2_audio').play()

      setTimeout(startStage3,3000)
    }

    function stage3end() {
      document.querySelector('.stage3').style.display = 'flex'
      document.querySelector('.stage3_audio').play()
      document.querySelector('.end').play()

      setTimeout(() => {
        document.querySelector('.canvas').style.display = 'none'

      },3000)

      setTimeout(() => {
        document.querySelector('.stage3').style.display = 'none'
        document.body.style.cursor = 'auto';
      },3700)

      setTimeout(() => {
        document.querySelector('.ending').style.display = 'block'
      },4400)
      setTimeout(() => {
        document.querySelector('.contact').style.display = 'flex'
      },5100)
      setTimeout(() => {
        document.querySelector('.portfolio').style.display = 'flex'
      },5800)
    }

    //start stage 2
    function startStage2() {
      document.querySelector('.stage1').style.display = 'none'
      me.rotation = 0
      me.x = -w
      me.y = -h
      me.zIndex = -2
      portrait = new PIXI.Sprite(texture_door)
      portrait.anchor.set(.5)
      portrait.y = h/2
      portrait.x = w/2
      portrait.zIndex = -1
      origin.x = w/2
      origin.y = h-100

      let texture = new PIXI.Texture(texture_hand2);
      hand.texture = texture
      thumb.alpha = 0
      step ++
      state = 'playing'
      stage.addChild(portrait)
    }

    //start stage 3
    function startStage3() {
      portrait.alpha = 0
      document.querySelector('.stage2').style.display = 'none'
      me.x = -w
      me.y = -h
      me.zIndex = -3
      pc = new PIXI.Sprite(texture_pc)
      pc.anchor.set(.5, .5)
      pc.y = h/2
      pc.x = w/2
      pc.zIndex = -1
      pc.interactive = true

      origin.x = w/3
      origin.y = h-100

      pc2 = new PIXI.Sprite(texture_pc)
      pc2.anchor.set(.5, .5)
      pc2.y = h/2
      pc2.x = w/2 + w
      pc2.zIndex = -1
      pc2.interactive = true


      pc3 = new PIXI.Sprite(texture_pc)
      pc3.anchor.set(.5, .5)
      pc3.y = h/2
      pc3.x = w/2 + w
      pc3.zIndex = -3
      pc3.interactive = true

      mask = new PIXI.Sprite(texture_mask)
      mask.anchor.set(.5, .5)
      mask.y = h/2 + 200
      mask.x = w/2 + w
      mask.zIndex = -1

      let texture = new PIXI.Texture(texture_hand3);
      hand.texture = texture
      thumb.alpha = 0
      step ++
      state = 'playing'
      stage.addChild(pc)
      stage.addChild(pc2)
      stage.addChild(pc3)
    }

    //playing stage2
    //playing
    function playStage2() {

      handPt.x = hand.x
      handPt.y = hand.y
      shoulderPt.x = hand.x + window.innerWidth/2
      shoulderPt.y = hand.y + window.innerHeight

      if(!hand.dragging) {
        moveTo(hand,origin)
        moveTo(handPt,origin)
        moveTo(thumb,origin)
      }


      if(hand.x < w/2 && Math.abs(hand.x - h/2) < 400 && !stage2_in && !stage2_drop) {
        portrait.rotation += .05
        stage2_in = true
      }

      if(hand.x > w/2 && stage2_in) {
        stage2_in = false
      }

      if(portrait.rotation > .15 && portrait.y < h*1.5 && stage2_step == 0) {
        stage2_drop = true
        portrait.y += 20
      }

      if(portrait.y >= h*1.5 && stage2_step == 0) {
        stage2_step  = 1

        let texture = new PIXI.Texture(texture_me2);
        me.texture = texture
        me.anchor.set(0.5, 1)
        me.x = w/2
        me.y = 0
      }

      if(stage2_step == 1)  {
        if(me.y < h+100) {
          me.y += 5
        } else {
          document.querySelector('.sacred').play()
          stage2_step = 2
          portrait.y = h + 200
        }
      }

      if(stage2_step == 2) {
        if(me.y > 200) {
          me.y -= 5
        } else {
          me.zIndex = -2
          stage2_step = 3
        }
        if(portrait.y > h/2 + 100) {
          portrait.y -= 5
        }
      }


      armPts.forEach((pt,index) => {
        pt.index = index

        if(index === 0) {
          updatePt(
            pt,
            armPts[index+1],
            shoulderPt
          )
        } else if (index === armPts.length-1) {
          updatePt(
            pt,
            handPt,
            armPts[index-1]
          )
        } else {
          updatePt(
            pt,
            armPts[index+1],
            armPts[index-1]
          )
        }
      })
    }


    //playing stage3
    //playing
    function playStage3() {

      handPt.x = hand.x
      handPt.y = hand.y
      shoulderPt.x = hand.x - window.innerWidth
      shoulderPt.y = hand.y + window.innerHeight

      if(!hand.dragging) {
        moveTo(hand,origin)
        moveTo(handPt,origin)
        moveTo(thumb,origin)
      }


      if(hand.dragging && Math.abs((hand.x+120) - pc.x) < 200 && Math.abs((hand.y-60) - pc.y) < 200 && stage3_step == 0) {
        pc.texture = texture_pc2

        stage3_step = 1

        document.querySelector('.bip').currentime = 0
        document.querySelector('.bip').play()

      }

      if(hand.dragging && pc2.x <= w/2 && Math.abs((hand.x+120) - pc2.x) < 200 && Math.abs((hand.y-60) - pc2.y) < 200 && stage3_step == 1) {
          pc2.texture = texture_pc3

          stage3_step = 2

          document.querySelector('.bip').currentime = 0
          document.querySelector('.bip').play()

          stage.addChild(mask)
      }


      if(hand.dragging && pc3.x <= w/2 && Math.abs((hand.x+120) - pc3.x) < 200 && Math.abs((hand.y-60) - pc3.y) < 200 && stage3_step == 2) {
          stage3_step = 3

          document.querySelector('.bip').currentime = 0
          document.querySelector('.bip').play()

          me.texture = texture_me3
          me.anchor.set(0,0)
          me.zIndex = -2
          me.x = w
          me.y = h/2 + 100
      }

      if(stage3_step === 1) {
        if(pc.x > -w) {
          pc.x-=3
        }
        if(pc2.x > w/2) {
          pc2.x-=3
        }
      }

      if(stage3_step === 2) {
        if(pc2.x > -w) {
          pc2.x-=3
        }
        if(pc3.x > w/2) {
          pc3.x-=3
          mask.x-=3
        }
      }

      if(stage3_step === 3) {
        if(me.x > w - 200) {
          me.x -= 5
          me.y -= 5
        } else {
          stage3_step = 4
        }
      }

      armPts.forEach((pt,index) => {
        pt.index = index

        if(index === 0) {
          updatePt(
            pt,
            armPts[index+1],
            shoulderPt
          )
        } else if (index === armPts.length-1) {
          updatePt(
            pt,
            handPt,
            armPts[index-1]
          )
        } else {
          updatePt(
            pt,
            armPts[index+1],
            armPts[index-1]
          )
        }
      })
    }

  }

  start() {
    state = 'playing'
  }

}

export default render;
